<template>
  <section>
    <b-card class="box-shadow">
      <b-row>
        <b-col
          sm="12"
          class="text-right"
        >
          <b-button
            v-if="hasProgramAdminRole"
            v-b-tooltip.hover.top="'Submissions have begun, only application dates can be edited.'"
            variant="primary"
            class="ml-2"
            @click="showMentorBulkValidateModal"
          >
            <span class="text-nowrap"><feather-icon
              class="mr-50"
              icon="UploadIcon"
              size="16"
            />{{ roleAlias( userRoles.MENTOR, defaultProgram) }}s : Validate application answers</span>
          </b-button>
          <b-button
            v-if="hasProgramAdminRole"
            variant="primary"
            class="ml-2"
            @click="showMenteeBulkValidateModal"
          >
            <span class="text-nowrap"><feather-icon
              class="mr-50"
              icon="UploadIcon"
              size="16"
            />{{ roleAlias( userRoles.MENTEE, defaultProgram) }}s : Validate application answers</span>
          </b-button>

          <action-button
            variant="primary"
            class="ml-2"
            :disabled="loading.fetching || !applicationSetId"
            @click="goToEditApplication"
            v-b-tooltip.hover.top="
              applicationSet.is_locked
                ? 'Submissions have begun, only application dates can be edited.'
                : ''
            "
          >
            <span class="text-nowrap"><feather-icon
              class="mr-50"
              icon="EditIcon"
              size="16"
            />Edit application</span>
          </action-button>
          <b-button
            v-if="viewMode === 'admin'"
            variant="primary"
            class="ml-2"
            :disabled="loading.fetching || loading.duplicating || !applicationSetId"
            @click="onDuplicateApplication"
          >
            <b-spinner
              v-if="loading.duplicating"
              small
            />
            <span
              v-if="loading.duplicating"
              class="text-nowrap"
            >Duplicating...</span>
            <span
              v-else
              class="text-nowrap"
            ><feather-icon
              class="mr-50"
              icon="CopyIcon"
              size="16"
            />Duplicate application</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-2"
            :disabled="loading.fetching || !applicationSetId"
            @click="printApplication"
          >
            <span
              class="text-nowrap"
            ><feather-icon
              class="mr-50"
              icon="PrinterIcon"
              size="16"
            />Print</span>
          </b-button>
          <b-button
            v-if="isChampionPortal"
            variant="primary"
            class="ml-2"
            :disabled="loading.fetching || !applicationSetId"
            @click="applicationReport"
          >
            <span
              class="text-nowrap"
            ><feather-icon
              class="mr-50"
              icon="BarChart2Icon"
              size="16"
            />Reports</span>
          </b-button>
          <b-button
            v-if="hasProgramAdminRole && !applicationSetId"
            variant="primary"
            class="ml-2"
            @click="showModalAddApplication()"
          >
            <feather-icon
              size="16"
              icon="PlusIcon"
              class="mr-50"
            />
            Add Application
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <!-- Mentor - Application -->
        <b-col sm="6">
          <question-skeleton-loader v-if="loading.fetching" />
          <b-row v-else>
            <b-col
              sm="8"
              class="mb-1"
            >
              <h5 class="mt-2">
                {{ roleAlias( userRoles.MENTOR, defaultProgram) }} - Application
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="!isLibrary">
            <b-col sm="6">
              <b-form-group label="Open Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(mentorOpenDate) }}
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Close Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(mentorCloseDate) }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card
            v-for="(question, index) in mentorQuestions"
            :key="question.id"
            no-body
            class="mb-1 box-shadow"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              :style="{ 'background-color': question.background }"
            >
              <b-col sm="10">
                <span>{{ index+1 }}. {{ question.translations[0].question_text }}</span>
              </b-col>

              <b-col
                sm="2"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <span
                  v-if="viewMode === 'champion' && question.is_visible"
                  class="font-small-1 mx-1"
                >
                  VISIBLE
                </span>
                <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                  <matched-question-weight :question="question" />
                </span>
              </b-col>
            </b-card-header>

            <b-card-body :class="{'overflow-scroll': question.type_id === questionTypes.SCALE}">
              <div v-if="question.type_id === questionTypes.TEXT">
                <label class="mt-1">Info text</label>
                <b-form-group label-for="prefilled-answer">
                  <b-form-textarea
                    :value="question.translations[0].prefilled_answer"
                    disabled
                    placeholder="Prefilled answer"
                    maxlength="255"
                    rows="3"
                  />
                </b-form-group>
              </div>
              <div v-if="question.type_id === questionTypes.CHOICE">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <b-form-radio
                            disabled
                            name="question-options"
                            class="mt-1"
                          />
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="
                              question.choices[i].translations[0].choice_text
                            "
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.MATCHING">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <b-form-radio
                            disabled
                            name="question-options"
                            class="mt-1"
                          />
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="question.choices[i].translations[0].choice_text"
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.SCALE">
                <b-row
                  v-for="indexRow in (question.statements.length + 1)" 
                  :key="indexRow" 
                  class="mt-1 flex-nowrap"
                >
                  <b-col 
                    v-for="indexCol in (question.choices.length + 2)"
                    :key="indexCol" 
                    class="col-sm-3"
                  >
                    <div
                      v-if="indexRow === 1 && indexCol === (question.choices.length + 2)"
                      class="minimal-width"
                    />

                    <div
                      v-if="indexRow === 1 && indexCol > 1 && indexCol < (question.choices.length + 2) "
                    >
                      <b-input-group 
                        :label-for="'option' + (indexCol-1) "
                      >
                        <b-form-textarea
                          v-if="question.choices[indexCol - 2].translations[0]"
                          disabled
                          :value="question.choices[indexCol - 2].translations[0].choice_text"
                          type="text"
                          class="min-statement-width"
                          :placeholder="'Option ' + (indexCol-1) "
                          style="overflow:hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div
                      v-else-if="indexRow > 1 && indexCol === 1"
                    >
                      <b-input-group 
                        :label-for="'statement' + (indexRow-1) "
                      >
                        <b-form-textarea
                          v-if="question.statements[indexRow - 2].translations[0]"
                          :value="question.statements[indexRow - 2].translations[0].question_text"
                          disabled
                          class="min-statement-width" 
                          type="text"
                          :placeholder="`Statement ${indexRow - 1}`"
                          style="overflow:hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div v-else-if="indexRow > 1 && indexCol > 1 && indexCol < (question.choices.length + 2)">
                      <b-form-radio
                        disabled
                        name="option-radio" 
                        class="ml-3"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Mentee - Application -->
        <b-col sm="6">
          <question-skeleton-loader v-if="loading.fetching" />
          <b-row v-else>
            <b-col
              sm="8"
              class="mb-1"
            >
              <h5 class="mt-2">
                {{ roleAlias( userRoles.MENTEE, defaultProgram) }} - Application
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="!isLibrary">
            <b-col sm="6">
              <b-form-group label="Open Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(menteeOpenDate) }}
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Close Date">
                <div class="d-flex align-items-center bg-light rounded p-1">
                  <feather-icon
                    class="mr-1"
                    icon="CalendarIcon"
                    size="20"
                  />
                  {{ localeDateStringFromIsoDateTimeWithoutTimezone(menteeCloseDate) }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card
            v-for="(question, index) in menteeQuestions"
            :key="question.id"
            no-body
            class="mb-1 box-shadow"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              :style="{ 'background-color': question.background }"
            >
              <b-col sm="10">
                <span>{{ index + 1 }}. {{ question.translations[0].question_text }}</span>
              </b-col>

              <b-col
                sm="2"
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <span
                  v-if="viewMode === 'champion' && question.is_visible"
                  class="font-small-1 mx-1"
                >
                  VISIBLE
                </span>
                <span v-if="question.type_id === questionTypes.MATCHING && question.question_matched_pair && question.question_matched_pair.weight">
                  <matched-question-weight :question="question" />
                </span>
              </b-col>
            </b-card-header>
            <b-card-body :class="{'overflow-scroll': question.type_id === questionTypes.SCALE}">
              <div v-if="question.type_id === questionTypes.TEXT">
                <label class="mt-1">Info text</label>
                <b-form-group label-for="prefilled-answer">
                  <b-form-textarea
                    :value="question.translations[0].prefilled_answer"
                    disabled
                    placeholder="Prefilled answer"
                    maxlength="255"
                    rows="3"
                  />
                </b-form-group>
              </div>
              <div v-if="question.type_id === questionTypes.CHOICE">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <b-form-radio
                            disabled
                            name="question-options"
                            class="mt-1"
                          />
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="
                              question.choices[i].translations[0]
                                .choice_text
                            "
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.MATCHING">
                <div
                  v-for="(choice, i) in question.choices"
                  :key="i"
                >
                  <b-row>
                    <b-col md="12">
                      <b-row>
                        <b-col md="1">
                          <b-form-radio
                            disabled
                            name="question-options"
                            class="mt-1"
                          />
                        </b-col>
                        <b-col md="11">
                          <b-form-input
                            :value="question.choices[i].translations[0].choice_text"
                            disabled
                            class="mt-1"
                            type="text"
                            :placeholder="`Option ${i + 1}`"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="question.type_id === questionTypes.STATEMENT">
                statements
              </div>
              <div v-if="question.type_id === questionTypes.SCALE">
                <b-row
                  v-for="indexRow in question.statements.length + 1"
                  :key="indexRow"
                  class="mt-1 flex-nowrap"
                >
                  <b-col
                    v-for="indexCol in question.choices.length + 2"
                    :key="indexCol"
                    class="col-sm-3"
                  >
                    <div
                      v-if="
                        indexRow === 1 &&
                          indexCol === question.choices.length + 2
                      "
                      class="minimal-width"
                    />

                    <div
                      v-if="
                        indexRow === 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <b-input-group :label-for="'option' + (indexCol - 1)">
                        <b-form-textarea
                          v-if="
                            question.choices[indexCol - 2].translations[0]
                          "
                          disabled
                          :value="
                            question.choices[indexCol - 2].translations[0]
                              .choice_text
                          "
                          type="text"
                          class="min-statement-width"
                          :placeholder="'Option ' + (indexCol - 1)"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div v-else-if="indexRow > 1 && indexCol === 1">
                      <b-input-group
                        :label-for="'statement' + (indexRow - 1)"
                      >
                        <b-form-textarea
                          v-if="
                            question.statements[indexRow - 2]
                              .translations[0]
                          "
                          v-model="
                            question.statements[indexRow - 2]
                              .translations[0].question_text
                          "
                          disabled
                          class="min-statement-width"
                          type="text"
                          :placeholder="`Statement ${indexRow - 1}`"
                          style="overflow: hidden"
                          size="sm"
                          no-resize
                        />
                      </b-input-group>
                    </div>
                    <div
                      v-else-if="
                        indexRow > 1 &&
                          indexCol > 1 &&
                          indexCol < question.choices.length + 2
                      "
                    >
                      <b-form-radio
                        disabled
                        name="option-radio"
                        class="ml-3"
                      />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <add-application-to-program
      v-if="hasProgramAdminRole && !applicationSetId"
      ref="addApplicationToProgram"
      :show-modal="showModal"
      @hiddenModal="hiddenModal"
      @applicationSetsAdded="onApplicationSetAdded"
    />
    <b-modal
      v-if="hasProgramAdminRole"
      v-model="showImportModal"
      :title="isMentorValidate? `${ roleAlias( userRoles.MENTOR, defaultProgram) }s: Validate application answers`: `${ roleAlias( userRoles.MENTEE, defaultProgram) }s: Validate application answers`"
      centered
    >
      <p v-if="isMentorValidate">
        Upload {{ roleAlias( userRoles.MENTOR, defaultProgram) }} application answers as CSV
      </p>
      <p v-else>
        Upload {{ roleAlias( userRoles.MENTEE, defaultProgram) }} application answers as CSV
      </p>
      <b-alert
        class="message-error mb-2 px-2 py-1"
        variant="danger"
        :show="!!validateError"
      >
        <p
          class="font-weight-normal"
          v-html="validateError"
        />
      </b-alert>
      <b-link
        class="text-primary"
        @click="downloadApplicationAnswers"
      >
        {{ isMentorValidate? `Download ${ roleAlias( userRoles.MENTOR, defaultProgram) } sample template`: `Download ${ roleAlias( userRoles.MENTEE, defaultProgram) } sample template` }}
      </b-link>

      <validation-observer
        ref="observer"
      >
        <validation-provider
          v-slot="validationContext"
          ref="file"
          :rules="{ required: true }"
          name="File"
        >
          <b-form-file
            ref="refInputEl"
            v-model="blogFile"
            :state="
              getValidationState(
                validationContext
              )
            "
            class="mt-3"
            placeholder="Add file"
            accept=".csv"
            drop-placeholder="Drop file here..."
            type="file"
            plain
          />
          <b-form-invalid-feedback
            :state="
              getValidationState(
                validationContext
              )
            "
          >
            {{
              validationContext.errors[0]
            }}
          </b-form-invalid-feedback>
        </validation-provider>
      </validation-observer>
      <!-- Modal Footer -->
      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            right
            @click="onValidateApplicationAnswers"
          >
            <b-spinner
              v-if="isValidating"
              style="margin-right: 5px"
              small
            />
            <span v-if="isValidating">Validatiing...</span>
            <span v-else>Validate</span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ActionButton from "@/views/components/common/ActionButton.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BButton,
  BModal,
  VBToggle,
  BCardBody,
  BFormTextarea,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BInputGroup,
  BSpinner,
  BFormFile,
  BAlert,
  BLink,
  BFormInvalidFeedback,
  VBTooltip
} from "bootstrap-vue";

import { questionTypes } from "@models/questionTypes";
import { userRoles } from "@models/userRoles";
import { formTypes } from "@models/formTypes";
import formsService from "@/services/formsService";
import QuestionSkeletonLoader from "../../questions/QuestionSkeletonLoader.vue";
import { pSBC, localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import { actions } from '@/models';
import programsService from "@/services/programsService";
import applicationService from "@/services/applicationService";
import { clearMatchLines } from '@/views/components/questions/matching/matching-lines.js';
import MatchedQuestionWeight from "@/views/apps/champion/matches/MatchedQuestionWeight.vue";
import { mapGetters } from 'vuex';
import AddApplicationToProgram from '@/views/components/forms/applications/AddApplicationToProgram.vue';
import { roleAlias } from "@/@aom-core/utils/utils";
import { BE_API_URL } from "@/constants/app";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { getValidationState, convertModelToFormData, makeSuccessToast, makeErrorToast} from "@/libs/utils";



export default {
  name: "ApplicationFormPreview",
  components: {
    ActionButton,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BButton,
    BCardHeader,
    BModal,
    BAlert,
    BFormTextarea,
    BFormGroup,
    BFormRadio,
    BFormInput,
    QuestionSkeletonLoader,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    MatchedQuestionWeight,
    BSpinner,
    BFormInvalidFeedback,
    BFormFile,
    BLink,
    AddApplicationToProgram,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  props: {
    viewMode: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      loading: {
        fetching: false,
        duplicating: false
      },
      mentorQuestions: [],
      menteeQuestions: [],
      applicationSet: {},
      questionTypes,
      mentorOpenDate: undefined,
      mentorCloseDate: undefined,
      menteeOpenDate: undefined,
      menteeCloseDate: undefined,
      showModal: false,
      showImportModal: false,
      validateError: null,
      browserLocale: navigator.language,
      localeDateStringFromIsoDateTimeWithoutTimezone,
      showModal:false,
      isValidating: false,
      blogFile: null,
      role: null
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    ...mapGetters('app', ['isAdminPortal', 'isChampionPortal']),
    ...mapGetters('profile', ['isProgramAdmin']),
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.$route.params.id));
    },
    isLibrary() {
      return !this.applicationSet || !this.applicationSet.program_id;
    },
    getFormType() {
      return { id: formTypes.APPLICATION, name: "Application" };
    },
    cssVars() {
      // Max matches in a app set
      const primary = "#3c6f95";
      const secondary = "#cdde8799";
      return {
        "--aom-color-primary-light-05": pSBC(0.4, primary, false, true),
        "--aom-color-primary-light-10": pSBC(0.5, primary, false, true),
        "--aom-color-primary-light-25": pSBC(0.6, secondary, false, true),
        "--aom-color-primary-light-45": pSBC(0.7, primary, false, true),
        "--aom-color-primary-light-65": pSBC(0.8, secondary, false, true),
        "--aom-color-primary-light-90": pSBC(0.9, primary, false, true),
      };
    },
    applicationSetId() {
      return this.defaultProgram?.application_set?.id || Number(this.$route.params.application);
    },
    isMentorValidate() {
      return this.role === userRoles.MENTOR;
    }
  },
  watch: {
    mentorQuestions: {
      handler(n) {
        const newSort = n.map((q, i) => ({ ...q, question_order: i }));
        const mentorIndex = this.applicationSet.applications.findIndex(a =>
          a.roles.every(role => role.id === userRoles.MENTOR)
        );
        this.applicationSet.applications[mentorIndex] = {
          ...this.applicationSet.applications[mentorIndex],
          questions: newSort,
        };
      },
      deep: true,
    },
    menteeQuestions: {
      handler(n) {
        const newSort = n.map((q, i) => ({ ...q, question_order: i }));
        const menteeIndex = this.applicationSet.applications.findIndex(a =>
          a.roles.every(role => role.id === userRoles.MENTEE)
        );
        this.applicationSet.applications[menteeIndex] = {
          ...this.applicationSet.applications[menteeIndex],
          questions: newSort,
        };
      },
      deep: true,
    },
    defaultProgram: {
      handler() {
        this.fetchApplicationSet();
      },
      deep :true
    }
  },
  created() {
    clearMatchLines();
    if(this.$route.params.application || this.defaultProgram?.application_set?.id) {
      this.fetchApplicationSet();
    }
  },
  methods: {
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    onApplicationSetAdded() {
      try {
        this.$store.dispatch('programs/FETCH_PROGRAM_BY_ID', this.defaultProgram.id);
      } catch (e) {
        console.log(e);
        const { data } = e.response;
        this.$toast(makeErrorToast(data?.message));
      }
    },
    showModalAddApplication() {
      this.showModal = true;
    },
    // @TODO Check what portal to call from to pass programId
    async fetchApplicationSet() {
      if (!this.applicationSetId) {
        return;
      }
      try {
        this.loading.fetching = true;
        let response = null;
        if (this.viewMode === 'champion') {
          response = await programsService.getApplicationSetByProgramId(
            this.$route.params.id,
            this.applicationSetId
          );
        } else {
          response = await formsService.getApplicationSet(
            this.$route.params.application
          );
        }
        const { data } = response;
        this.applicationSet = data;
        const { mentee, mentor } = this.mapApplicationData(data);
        this.menteeQuestions = mentee;
        this.mentorQuestions = mentor;
        const {mentorOpenDate, mentorCloseDate, menteeOpenDate, menteeCloseDate} = this.mapScheduledActionDates(data);
        this.mentorOpenDate = mentorOpenDate;
        this.mentorCloseDate = mentorCloseDate;
        this.menteeOpenDate = menteeOpenDate;
        this.menteeCloseDate = menteeCloseDate;
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.loading.fetching = false;
      }
    },
    downloadApplicationAnswers() {

      const dowloadLink = `${BE_API_URL}/programs/${this.defaultProgram.id}/applications/role/${this.role}/results/download/csv`;
      window.document.location = dowloadLink;
    },
    async onValidateApplicationAnswers() {
      this.$refs.observer.validate().then(async valid => {
        if(!valid) {
          return;
        }
        try {
          this.isValidating = true;
          const programId = this.defaultProgram.id;
          await applicationService.validateApplicationAnswers(
            programId, this.role,
            convertModelToFormData({file: this.blogFile})
          );
          this.$toast(makeSuccessToast("The data has been validated and has no errors (can be submitted to the admin for upload)."));
          this.hideBulkImportModal();
        } catch(e) {
          const { status, data } = e.response;
          if (status === 422 && data.errors) {
            const formatMessage = mess => {
              if (/The \d+\./.test(mess)) {
                const words = mess.split(' ');
                words[0] = "Row";
                words[1] = words[1].replace(/\.|_/g, ' ');
                return words.join(" ");
              }
              return mess;
            };
            const messages = [];
            Object.keys(data.errors).map((key, index) => {
              if (data.errors[key] && index <= 5) {
                messages.push(formatMessage(data.errors[key][0]));
              }
            });
            this.validateError = messages.join("<br/>");
            this.$refs.observer.setErrors(data.errors);
          } else {
            this.$toast(makeErrorToast(data.message));
          }
        } finally {
          this.isValidating = false;
        }
      });
    },
    showMentorBulkValidateModal() {
      this.showImportModal = true;
      this.validateError = null;
      this.role = userRoles.MENTOR;
    },
    showMenteeBulkValidateModal() {
      this.showImportModal = true;
      this.validateError = null;
      this.role = userRoles.MENTEE;
    },
    mapApplicationData(data) {
      // Maps matching questions with the same background color
      const menteeQ = data.applications.filter(a =>
        a.roles.every(role => role.id === userRoles.MENTEE)
      );
      const mentorQ = data.applications.filter(a =>
        a.roles.every(role => role.id === userRoles.MENTOR)
      );
      const backColors = Object.values(this.cssVars);
      const mappedMatchingQ = menteeQ[0].questions.filter(
        mq => mq.type_id === questionTypes.MATCHING
      );
      const filteredMatches = mappedMatchingQ.map((q, index) => {
        if (q.type_id === questionTypes.MATCHING) {
          const backGroundColorObj = { background: backColors[index] };
          return Object.assign({}, q, backGroundColorObj);
        }
        return q;
      });
      const menteeQuestions = menteeQ[0].questions.map(q => {
        if (q.type_id === questionTypes.MATCHING) {
          return filteredMatches.find(f => f.id === q.id);
        }
        return q;
      });
      const mentorQuestions = mentorQ[0].questions.map(mq => {
        if (mq.type_id === questionTypes.MATCHING) {
          const findMatch = filteredMatches.find(
            f => f.question_matched_pair_id === mq.question_matched_pair_id
          );
          if (findMatch) {
            const backGroundColorObj = { background: findMatch.background };
            return Object.assign({}, mq, backGroundColorObj);
          }
          return mq;
        }
        return mq;
      });
      return { mentee: menteeQuestions, mentor: mentorQuestions };
    },
    mapScheduledActionDates(data) {
      const dates = {
        mentorOpenDate: undefined,
        mentorCloseDate: undefined,
        menteeOpenDate: undefined,
        menteeCloseDate: undefined
      };
      const menteeForm = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTEE))[0];
      const mentorForm = data.applications.filter(a => a.roles.every(role => role.id === userRoles.MENTOR))[0];

      if (menteeForm && menteeForm.scheduled_actions) {
        const open = menteeForm.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0];
        const close = menteeForm.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0];
        if (open) {
          dates.menteeOpenDate = open.absolute_date;
        }
        if (close) {
          dates.menteeCloseDate = close.absolute_date;
        }
      }

      if (mentorForm && mentorForm.scheduled_actions) {
        const open = mentorForm.scheduled_actions.filter(sa => sa.action_id === actions.SHOW)[0];
        const close = mentorForm.scheduled_actions.filter(sa => sa.action_id === actions.HIDE)[0];
        if (open) {
          dates.mentorOpenDate = open.absolute_date;
        }
        if (close) {
          dates.mentorCloseDate = close.absolute_date;
        }
      }

      return dates;
    },
    goToEditApplication () {
      // Champion portal
      if (this.viewMode === 'champion') {
        this.$router.push({
          name: 'champion-program-application-edit',
          params:{ id: this.$route.params.id, application: this.defaultProgram.application_set.id} 
        });
      } else {
        // Admin portal
        if (this.$route.name === 'admin-program-application') {
          // Program application
          this.$router.push({
            name: 'admin-program-application-edit'
          });
        } else {
          // Library application
          this.$router.push({
            name: 'admin-application-edit'
          });
        }

      }
    },
    async onDuplicateApplication() {
      try {
        this.loading.duplicating = true;
        const response = await formsService.duplicateLibraryApplicationSet(
          this.defaultProgram?.application_set?.id || this.$route.params.application
        );
        if (response) {
          this.$router.push({
            name: 'admin-application-list'
          });
        }
      } catch (e) {
        const { data } = e.response;
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.loading.duplicating = false;
      }
    },
    printApplication() {
      this.$router.push({
        name: 'print-application-matching-questions',
        params: {
          id: this.$route.params.id,
          applicationId: this.$route.params.application || this.defaultProgram?.application_set?.id
        }
      });
    },
    applicationReport() {
      if (this.isChampionPortal) {
        this.$router.push({
          name: "champion-program-application-result",
          params: {
            id: this.$route.params.id,
            application: this.defaultProgram.application_set.id
          }
        });
      }
    }
  },
  setup() {
    return {
      roleAlias,
      userRoles,
      getValidationState
    };
  },
};
</script>

<style lang="scss" scoped>
.matching {
  background-color: #a6e490 !important;
  color: #348818;
}
.box-shadow {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.overflow-scroll {
  overflow: scroll;
}
.movable {
  cursor: move;
}
</style>